import React, {Component} from 'react';
import './festivalInfo.scss';

class FestivalInfo extends Component {

  render() {

    return (
      null

      /* Leaving bottom drawer details out in 2022 app
      
      <div className="festivalInfo">
          General information, first aid, and missing child assistance.
          <br/><br />
          T-shirt and festival swag for purchase.
          <br /><br />
          Volunteers are standing by to help with all of your festival questions!
      </div>*/
    );
  }
}

export default FestivalInfo;